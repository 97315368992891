import PhoneIcon from '../assets/images/icon-phone.svg';
import RxIcon from '../assets/images/icon-rx.svg';

const contactCopy = {
  pageTitle: 'Contact Us',
  pageHeader: 'Please contact us for support and more information',

  rows: [
    {
      title: 'Ask Merck',
      body: `
        <p>Merck is committed to helping you get your oncology product questions answered.</p>
        <p>Connect with a Merck Associate now or schedule a call by visiting <a href="https://www.askmerckoncology.com" target="_blank">Ask Merck</a>. Merck Associates are available Monday through Friday, 8 AM to 8 PM ET.</p>
      `,
      icon: PhoneIcon
    },
    {
      title: 'Access & Reimbursement Support',
      body: `
        <p>The Merck Access Program may help answer questions about billing and coding, coverage and reimbursement information, co-pay assistance for eligible patients, access to free product for eligible patients, and product distribution.</p>
        <p>Call <a href="tel:8552573932">855-257-3932</a> Monday through Friday, 8 AM to 8 PM ET, to speak with a Merck Access Program representative or visit <a href="https://www.merckaccessprogram-welireg.com/hcp/" target="_blank">The Merck Access Program</a>.</p>
      `,
      icon: RxIcon
    },
  ],

  alsoInterested: {
    header: 'You might also be interested in:',
    buttons: [
      {
        label: 'Dosing & Dose Modifications',
        href: '/von-hippel-lindau/dosing',
      },
      {
        label: 'Efficacy for WELIREG (belzutifan)',
        href: '/von-hippel-lindau/efficacy',
      },
      {
        label: 'Mechanism of Action',
        href: '/von-hippel-lindau/mechanism-of-action',
      },
    ],
  },

  buttonBlade: {
    title: 'Learn more about these attributes of WELIREG:',
    buttonLabelLeft: 'Mechanism of Action',
    buttonHrefLeft: '/von-hippel-lindau/mechanism-of-action',
    buttonLabelMid: 'Adverse Reaction Profile',
    buttonHrefMid: '/von-hippel-lindau/adverse-reaction-profile',
    buttonLabelRight: 'Dosing',
    buttonHrefRight: '/von-hippel-lindau/dosing',
  },
};


export default contactCopy;
