import * as React from 'react';
import styled from 'styled-components';
import PageSeo from '../components/Seo/PageSeo';
import PageTitle from '../components/PageTitle';
import PageSection from '../components/PageSection';
import { H3 } from '../styles/shared/typography';
import contactCopy from '../copy/contact-us';
import { respondTo } from '../styles/shared/breakpoints';
import { InformationalIcons } from '../components';

// SEO INFORMATION
const pageMeta = {
  title: 'Contact Us | WELIREG® (belzutifan) for Health Care Professionals',
  keywords: 'contact information for welireg',
  description: 'For questions about WELIREG® (belzutifan), health care professionals may connect with a Merck Associate through AskMerck.com or learn more about billing and coding at The Merck Access Program.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/contact-us/","@type":"MedicalWebPage","name":"Contact Us","description":"Merck is committed to helping you get your oncology product questions answered. Connect with a Merck Associate now or schedule a call by visiting Ask Merck. Merck Associates are available Monday through Friday, 8 AM to 8 PM ET."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/contact-us/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`
  ]
}

const ContactUsPageSection = styled(PageSection)`
  padding-top: 20px;
  padding-bottom: 0px;

  ${respondTo.sm`
    padding-top: 28px;

    &.contact-wrapper{
     [class*="styles__SectionWrapper"]{
        h3 +  [class*="InformationalIcons__Root"]{
          margin-bottom: 30px;
        }
      }
    }
  `}
`

const StyledH3 = styled(H3)`
  margin-bottom: 32px;
  font-size: 28px;
  font-style: normal;
  line-height: 130%; /* 36.4px */
  ${respondTo.sm`
    margin-bottom: 42px;
  `}  
`

const ContactUsPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/contact-us/"/>
      </PageSeo>
      <PageTitle unbranded>
        { contactCopy.pageTitle }
      </PageTitle>

      <ContactUsPageSection className="contact-wrapper">
        <StyledH3>
          { contactCopy.pageHeader }
        </StyledH3>

        {contactCopy.rows.map((row, i) => (
          <InformationalIcons 
            key={i}
            title={row.title}
            icon={row.icon}
            body={row.body}
          >
          </InformationalIcons>

        ))}
      </ContactUsPageSection>
    </>
  );
};


export default ContactUsPage;
